import { collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../data/firebase";
import { deleteCollection } from "./commonOperation/CommonOperations";


export const fetchInstructorCourses=async(user)=>{
    const result=  doc(db, "Active Users", user.email);
    const resultSnap = await getDoc(result);
    const coursePromises = resultSnap.data().Courses.map(async (element) => {
        const curr = doc(db, `${element.Course}`, "Detail");
        const currSnap = await getDoc(curr);
        return currSnap.data();
    });

    // Wait for all promises to resolve and return the courses array
    const courses = await Promise.all(coursePromises);
    return courses;
}



export const getFullDetailsOfCourse=async(courseId)=>{
    let course= {
        // Course Information (Step 1)
        name: "",
        description: "",
        price: "",
        thumbnailLink: "",
        afterCompletion: "",
        duration: "",
        mission: "",
        noteToStudents: "",
        outcome: "",
        categories: "",
        
        // Course Builder (Step 2)
        sections: [],
        
        // Quiz Builder (Step 3)
        quiz: [],
        freeVideos:[]
      };

    try{  
    const courseDoc=doc(db,courseId, "Detail");
    const courseDetailSnap=await getDoc(courseDoc);
    if(courseDetailSnap.exists()){
        course.name=courseDetailSnap.data().Name;
        course.description=courseDetailSnap.data().Description;
        course.price=courseDetailSnap.data().Price;
        course.thumbnailLink=courseDetailSnap.data().Photo;
        course.afterCompletion=courseDetailSnap.data().After_Completion;
        course.duration=courseDetailSnap.data().Duration;
        course.mission=courseDetailSnap.data().Mission;
        course.noteToStudents=courseDetailSnap.data().Note_To_Students;
        course.outcome=courseDetailSnap.data().Outcome;
        course.categories=courseDetailSnap.data().Category;
    }
    let section=[];
    let i=1;
    while(true){
        const currSecDoc=doc(db,courseId,`${i}`);
        const currSecSnap=await getDoc(currSecDoc);
        if(currSecSnap.exists()){
            const currData=currSecSnap.data();
            section=[...section,{lectures:currData.Lectures}];
            i++;
        }
        else{
            course.sections=section;
            break;
        }
    }
    const quizDoc=doc(db,"Quiz Questions",courseId);
    const quizDocSnap=await getDoc(quizDoc);
    if(quizDocSnap.exists()){
        course.quiz=quizDocSnap.data().Questions;
    }
    const freeVideosDoc=doc(db,"Free Videos",courseId);
    const freeVideoSnap=await getDoc(freeVideosDoc);
    if(freeVideoSnap.exists()){
        course.freeVideos=freeVideoSnap.data().Video;
    }


    return course;

   }catch(e){
    console.log("some error occured in edit course:",e);
    prompt("some error occured");
   }
}


export async  function  deleteCourse(courseId){
  try{
    await deleteCollection(db,courseId);
    const collectionRef = collection(db, "Active Users");
    const snapshot = await getDocs(collectionRef);
    const batchPromises = snapshot.docs.map(async (document) => {
        const data = document.data();
        const courses = data.Courses || [];

        // Filter out the course with the given courseId
        const updatedCourses = courses.filter(course => course.Course !== courseId);

        // If the array was changed, update the document
        if (updatedCourses.length !== courses.length) {
            const docRef = doc(db, "Active Users", document.id);
            await updateDoc(docRef, { Courses: updatedCourses });
        }
    });

    // Wait for all updates to complete
    await Promise.all(batchPromises);

    const docRef = doc(db, "Free Videos", courseId);
    const docSnapshot = await getDoc(docRef);
    if(docSnapshot.exists()){
        await deleteDoc(docRef);
    }
    
    const quizdocRef = doc(db, "Quiz Questions", courseId);
    const quizdocSnapshot = await getDoc(quizdocRef);
    if(quizdocSnapshot.exists()){
        await deleteDoc(quizdocRef);
    }

  }
  catch(e){
    prompt("Something went wrong, try again later")
    console.error("error occured while deleting course:",e);
  }
}