import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  step: 1,
  course: {
    // Course Information (Step 1)
    name: "",
    description: "",
    price: "",
    thumbnailLink: "",
    afterCompletion: "",
    duration: "",
    mission: "",
    noteToStudents: "",
    outcome: "",
    categories: "",
    
    // Course Builder (Step 2)
    sections: [],
    
    // Quiz Builder (Step 3)
    quiz: [],
    freeVideos:[]
  },
  editCourse: false,
}


const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    updateCourseInformation: (state, action) => {
      state.course = { ...state.course, ...action.payload };
    },
    updateSections: (state, action) => {
      state.course.sections = action.payload;
    },
    updateFreevideos: (state, action) => {
      state.course.freeVideos = action.payload;
    },
    updateQuizzes: (state, action) => {
      state.course.quiz = action.payload;
    },
    setEditCourse: (state, action) => {
      state.editCourse = action.payload;
    },
    resetCourseState: (state) => {
      state.step = 1;
      state.course = initialState.course;
      state.editCourse = false;
    },
  },
});



export const {
  setStep,
  updateCourseInformation,
  updateSections,
  updateQuizzes,
  setEditCourse,
  resetCourseState,
  updateFreevideos
} = courseSlice.actions;

export default courseSlice.reducer