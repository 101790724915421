import React from 'react';
import video from "../assets/videos/InstructorGuide.mp4" 
const VideoSection = () => {
  return (
    <section className="video-tutorial-section container mx-auto px-4 py-12">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Instructor Course Creation Guide
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Watch our comprehensive tutorial on how to create courses and manage your instructor dashboard efficiently.
        </p>
      </div>
      <div className="video-container max-w-4xl mx-auto rounded-lg overflow-hidden shadow-lg">
        <video 
          controls 
          className="w-full"
        >
          <source 
            src={video}
            type="video/mp4" 
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default VideoSection;