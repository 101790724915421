import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStep, updateSections, updateFreevideos } from '../../../reducers/AddCourseSlice';
import { MdClose, MdAddCircle, MdEdit, MdDelete } from 'react-icons/md';

const CourseBuilder = () => {
  const dispatch = useDispatch();
  const { course } = useSelector((state) => state.course);
  const [sections, setSections] = useState(course.sections || []);
  const [freeVideos, setFreeVideos] = useState(course.freeVideos || []);
  const [newFreeVideoLink, setNewFreeVideoLink] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (course.sections) {
      setSections(course.sections);
    }
    if (course.freeVideos) {
      setFreeVideos(course.freeVideos);
    }
  }, [course.sections, course.freeVideos]);

  const validateSections = () => {
    const newErrors = {};
    
    if (sections.length === 0) {
      newErrors.sections = 'At least one section is required';
      return false;
    }

    let isValid = true;
    sections.forEach((section, sectionIndex) => {
      if (!section.lectures || section.lectures.length === 0) {
        newErrors[`section${sectionIndex}Lectures`] = 'At least one lecture is required';
        isValid = false;
      } else {
        section.lectures.forEach((lecture, lectureIndex) => {
          if (!lecture.Video_Heading?.trim()) {
            newErrors[`section${sectionIndex}Lecture${lectureIndex}Title`] = 'Lecture title is required';
            isValid = false;
          }
          if (!lecture.Video_Description?.trim()) {
            newErrors[`section${sectionIndex}Lecture${lectureIndex}Description`] = 'Lecture description is required';
            isValid = false;
          }
          if (!lecture.Video_link?.trim()) {
            newErrors[`section${sectionIndex}Lecture${lectureIndex}Video`] = 'Video link is required';
            isValid = false;
          }
        });
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        lectures: []
      }
    ]);
  };

  const deleteSection = (sectionIndex) => {
    const newSections = sections.filter((_, index) => index !== sectionIndex);
    setSections(newSections);
  };

  const addLecture = (sectionIndex) => {
    const updatedSections = sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          lectures: [
            ...section.lectures,
            {
              Video_Heading: '',
              Video_Description: '',
              Video_link: ''
            }
          ]
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const deleteLecture = (sectionIndex, lectureIndex) => {
    const updatedSections = sections.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          lectures: section.lectures.filter((_, idx) => idx !== lectureIndex)
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const updateLecture = (sectionIndex, lectureIndex, field, value) => {
    const updatedSections = sections.map((section, index) => {
      if (index === sectionIndex) {
        const updatedLectures = section.lectures.map((lecture, idx) => {
          if (idx === lectureIndex) {
            return {
              ...lecture,
              [field]: value
            };
          }
          return lecture;
        });
        return {
          ...section,
          lectures: updatedLectures
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const addFreeVideo = () => {
    if (newFreeVideoLink.trim()) {
      const updatedFreeVideos = [...freeVideos, newFreeVideoLink];
      setFreeVideos(updatedFreeVideos);
      setNewFreeVideoLink(''); // Reset input
      dispatch(updateFreevideos(updatedFreeVideos));
    }
  };

  // Remove a free video link
  const removeFreeVideo = (index) => {
    const updatedFreeVideos = freeVideos.filter((_, idx) => idx !== index);
    setFreeVideos(updatedFreeVideos);
    dispatch(updateFreevideos(updatedFreeVideos));
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && newFreeVideoLink.trim()) {
      addFreeVideo();
    }
  };
  const handleBack = () => {
    dispatch(updateSections(sections));
    dispatch(setStep(1));
  };

  const handleNext = () => {
    if (validateSections()) {
      dispatch(updateSections(sections));
      dispatch(setStep(3));
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Course Builder</h2>
      <div className="mb-6 p-4 border-l-4 border-blue-500 bg-blue-50 rounded">
        <p className="text-blue-700 font-medium">
          Please ensure video links follow this format: <span className="font-semibold">https://youtu.be/your_video_id</span>
        </p>
      </div>
      <div className="mb-8 border rounded-lg p-4 bg-white shadow">
        <h3 className="text-xl font-semibold mb-4">Free Preview Videos</h3>
        
        {/* Add Free Video Link Input */}
        <div className="flex gap-2 mb-4">
          <input
            type="text"
            value={newFreeVideoLink}
            onChange={(e) => setNewFreeVideoLink(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter video link"
            className="flex-1 p-2 border rounded"
          />
          <button
            onClick={addFreeVideo}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 flex items-center"
          >
            <MdAddCircle className="mr-2" size={20} />
            Add
          </button>
        </div>

        {/* Free Video Links List */}
        {freeVideos.length > 0 && (
          <div className="space-y-2">
            {freeVideos.map((link, index) => (
              <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded">
                <span className="text-blue-600 break-all mr-2">{link}</span>
                <button
                  onClick={() => removeFreeVideo(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  <MdDelete size={20} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Sections List */}
      <div className="space-y-6">
        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="border rounded-lg p-4 bg-white shadow">
            {/* Section Header */}
            <div className="flex items-center justify-between mb-4">
              <div className="flex-1">
                <h3 className="text-xl font-semibold">Section {sectionIndex + 1}</h3>
              </div>
              <button
                onClick={() => deleteSection(sectionIndex)}
                className="ml-2 text-red-600 hover:text-red-800"
              >
                <MdDelete size={24} />
              </button>
            </div>

            {/* Lectures List */}
            <div className="space-y-4 ml-4">
              {section.lectures.map((lecture, lectureIndex) => (
                <div key={lectureIndex} className="border rounded p-3 bg-gray-50">
                  <div className="flex justify-between items-start">
                    <div className="flex-1 space-y-2">
                      <input
                        type="text"
                        value={lecture.Video_Heading}
                        onChange={(e) => updateLecture(sectionIndex, lectureIndex, 'Video_Heading', e.target.value)}
                        placeholder="Lecture Title"
                        className={`w-full p-2 border rounded ${
                          errors[`section${sectionIndex}Lecture${lectureIndex}Title`] ? 'border-red-500' : 'border-gray-300'
                        }`}
                      />
                      <textarea
                        value={lecture.Video_Description}
                        onChange={(e) => updateLecture(sectionIndex, lectureIndex, 'Video_Description', e.target.value)}
                        placeholder="Lecture Description"
                        className={`w-full p-2 border rounded ${
                          errors[`section${sectionIndex}Lecture${lectureIndex}Description`] ? 'border-red-500' : 'border-gray-300'
                        }`}
                        rows="2"
                      />
                      <input
                        type="text"
                        value={lecture.Video_link}
                        onChange={(e) => updateLecture(sectionIndex, lectureIndex, 'Video_link', e.target.value)}
                        placeholder="Video Link"
                        className={`w-full p-2 border rounded ${
                          errors[`section${sectionIndex}Lecture${lectureIndex}Video`] ? 'border-red-500' : 'border-gray-300'
                        }`}
                      />
                    </div>
                    <button
                      onClick={() => deleteLecture(sectionIndex, lectureIndex)}
                      className="ml-2 text-red-600 hover:text-red-800"
                    >
                      <MdClose size={20} />
                    </button>
                  </div>
                  {/* Error messages for lecture fields */}
                  {Object.keys(errors)
                    .filter(key => key.startsWith(`section${sectionIndex}Lecture${lectureIndex}`))
                    .map(key => (
                      <p key={key} className="text-red-500 text-sm mt-1">{errors[key]}</p>
                    ))}
                </div>
              ))}
              
              {/* Add Lecture Button */}
              <button
                onClick={() => addLecture(sectionIndex)}
                className="flex items-center text-indigo-600 hover:text-indigo-800 mt-2"
              >
                <MdAddCircle className="mr-1" size={20} />
                Add Lecture
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Add Section Button */}
      <button
        onClick={addSection}
        className="mt-6 flex items-center justify-center w-full py-3 border-2 border-dashed border-gray-400 text-gray-600 hover:border-indigo-600 hover:text-indigo-600 rounded-lg"
      >
        <MdAddCircle className="mr-2" size={24} />
        Add Section
      </button>

      {/* Error message for no sections */}
      {errors.sections && (
        <p className="text-red-500 text-sm mt-2">{errors.sections}</p>
      )}

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-8">
        <button
          onClick={handleBack}
          className="px-6 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
        >
          Back
        </button>
        <button
          onClick={handleNext}
          className="px-6 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CourseBuilder;