import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStep, updateCourseInformation } from '../../../reducers/AddCourseSlice';
import ChipInput from '../ChipInput';

export default function CourseInformation () {
  const dispatch = useDispatch();
  const { course } = useSelector((state) => state.course);
  const [formData, setFormData] = useState(course);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(course);
  }, [course]);

  const VALID_CATEGORIES = [
    'Research',
    'Data Science',
    'Web Development',
    'Advanced Technology',
    'Summer School',
    'Internship'
  ];
  

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'name','afterCompletion','description', 'price','mission','noteToStudents','thumbnailLink', 
      'duration','outcome', 'categories'
    ];

    requiredFields.forEach(field => {
      if (!formData[field] || 
          (Array.isArray(formData[field]) && formData[field].length === 0)) {
        newErrors[field] = 'This field is required';
        console.log("error", field);
      }
    });

    if (formData.categories === "") {
      newErrors.categories = 'At least one category is required';
      console.log("error in categories");
    }

    setErrors(newErrors);
    console.log("oberall error", Object.keys(newErrors).length === 0)
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCategoriesChange = (categories) => {
    let categoriesValues=categories.join(';');
    setFormData(prev => ({
      ...prev,
      categories:categoriesValues
    }));
  };

   function changeCategoryToArray(categories){
    if(categories.length!==0){
    const array = categories.split(";");
    return array;
    }
    return [];
  }
  const handleNext = () => {
    if (validateForm()) {
      dispatch(updateCourseInformation(formData));
      dispatch(setStep(2));
    }
  };

  return (
    <div className="p-6">
       <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
        <h3 className="font-bold text-lg mb-2">Course Creation Guidelines:</h3>
        <ul className="list-disc pl-4 space-y-1">
          <li>For Mission, Note to Students, and After Completion: Separate multiple items with semicolons (;)</li>
          <li>Example: "Introduction; What is Research; Types of Research"</li>
          <li>Thumbnail must be a valid URL (starting with http:// or https://)</li>
          <li>Categories must be selected from the following options only:</li>
          <ul className="list-disc pl-4">
            {VALID_CATEGORIES.map(cat => (
              <li key={cat}>{cat}</li>
            ))}
          </ul>
        </ul>
      </div>
      <form className="space-y-6">
        {/* Form fields */}
        <div>
          <label className="block text-4xl font-medium text-gray-700">
            Course Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400 ${
              errors.name ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
          )}
        </div>
        <div>
          <ChipInput
            label="Categories"
            name="categories"
            placeholder="Enter a category and press Enter"
            setValue={handleCategoriesChange}
            initialValue={changeCategoryToArray(formData.categories)}
          />
          {errors.categories && (
            <p className="text-red-500 text-sm mt-1">{errors.categories}</p>
          )}
        </div>
        <div>
        <label htmlFor="description" className="block text-4xl font-medium text-gray-700 ">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"
          rows="3"
        ></textarea>
        {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
      </div>
      <div>
        <label htmlFor="afterCompletion" className="block text-4xl font-medium text-gray-700 ">After Completion</label>
        <textarea
          id="afterCompletion"
          name="afterCompletion"
          value={formData.afterCompletion}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"
          rows="3"
        ></textarea>
        {errors.afterCompletion && (
            <p className="text-red-500 text-sm mt-1">{errors.afterCompletion}</p>
          )}
      </div>
      <div>
      <label htmlFor="duration" className="block text-4xl font-medium text-gray-700">Duration</label>
        <input
          type="text"
          id="duration"
          name="duration"
          value={formData.duration}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"
        />
        {errors.duration && (
            <p className="text-red-500 text-sm mt-1">{errors.duration}</p>
          )}
      </div>
      <div>
      <label htmlFor="thumbnailLink" className="block text-4xl font-medium text-gray-700">Thumbnail</label>
        <input
          type="text"
          id="thumbnailLink"
          name="thumbnailLink"
          value={formData.thumbnailLink}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"
        />
        {errors.thumbnailLink && (
            <p className="text-red-500 text-sm mt-1">{errors.thumbnailLink}</p>
          )}
      </div>
      <div>
      <label htmlFor="price" className="block text-4xl font-medium text-gray-700">Price</label>
        <input
          type="text"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"
        />
        {errors.price && (
            <p className="text-red-500 text-sm mt-1">{errors.price}</p>
          )}
      </div>
      <div>
      <label htmlFor="mission" className="block text-4xl font-medium text-gray-700">Mission</label>
        <textarea
          id="mission"
          name="mission"
          value={formData.mission}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"          rows="3"
        ></textarea>
        {errors.mission && (
            <p className="text-red-500 text-sm mt-1">{errors.mission}</p>
          )}
      </div>
      <div>
      <label htmlFor="noteToStudents" className="block text-4xl font-medium text-gray-700">Note to Students</label>
        <textarea
          id="noteToStudents"
          name="noteToStudents"
          value={formData.noteToStudents}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"          rows="3"
        ></textarea>
        {errors.noteToStudents && (
            <p className="text-red-500 text-sm mt-1">{errors.noteToStudents}</p>
          )}
      </div>
      <div>
      <label htmlFor="outcome" className="block text-4xl font-medium text-gray-700">Outcome</label>
        <textarea
          id="outcome"
          name="outcome"
          value={formData.outcome}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10  bg-slate-400"          rows="3"
        ></textarea>
        {errors.outcome && (
            <p className="text-red-500 text-sm mt-1">{errors.outcome}</p>
          )}
      </div>
   

        <div className="flex justify-between mt-8">
          <button
            type="button"
            onClick={handleNext}
            className="bg-blue-600 text-white px-4 py-2 rounded"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
