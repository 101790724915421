import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { getFullDetailsOfCourse } from "../../operations/courseDetailsAPI"
import { setEditCourse, updateCourseInformation , setStep} from "../../reducers/AddCourseSlice"
import RenderSteps from "../AddCourse/RenderSteps"

export default function EditCourse() {
  const dispatch = useDispatch()
  let { courseId } = useParams()

  const { course } = useSelector((state) => state.course)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const func=async () => {
      setLoading(true)
    courseId=courseId.replace(/Course(\d+)/, 'Course $1');
    
      const result = await getFullDetailsOfCourse(courseId)
      if (result) {
        dispatch(setEditCourse(true))
        dispatch(updateCourseInformation(result))
        dispatch(setStep(1));
      }
      setLoading(false)
    }
    func();
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full min-h-[calc(100vh-10.5rem)]">
        <div className="custom-loader"></div>
      </div>
    )
  }

  return (
    <div>
      <h1 className="mb-14 text-3xl font-medium text-richblack-5 mt-5 ml-5">
        Edit Course
      </h1>
      <div className="mx-auto max-w-[600px]">
        {course ? (
          <RenderSteps />
        ) : (
          <p className="mt-14 text-center text-3xl font-semibold text-richblack-100">
            Course not found
          </p>
        )}
      </div>
    </div>
  )
}