import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { RevolvingDot } from 'react-loader-spinner'
const Signup = () => {
    const navigate = useNavigate();
    const { googleSignIn, passwordSignIn, user } = UserAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const[accountType, setAccountType]=useState('Student');

    const handleGoogleSignIn = async () => {
        setLoading(true);
        setError('');
        try {
            await googleSignIn(accountType);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            let errorMessage = [];
            errorMessage = error.message.split('/')[1].split(')')[0].split('-');
            let errorString = '';
            errorMessage.forEach(str => {
                errorString += str;
                errorString += ' ';
            })
            setError(errorString.toLocaleUpperCase());
        }
    }

    useEffect(() => {
        if (user !== null || localStorage.getItem('person') !== null) {
            setLoading(false);
            setError('');
            // Update state with user data from local storage
            const userData = JSON.parse(localStorage.getItem('person'));
            setEmail(userData.email); // Assuming you have email stored in user data
            // Update any other relevant state variables here
            navigate('/');
        }
    }, [user, navigate]);

    const handlePasswordLoginIn = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        try {
            await passwordSignIn(email, password);
        } catch (error) {
            setLoading(false);
            let errorMessage = [];
            errorMessage = error.message.split('/')[1].split(')')[0].split('-');
            let errorString = '';
            errorMessage.forEach(str => {
                errorString += str;
                errorString += ' ';
            })
            setError(errorString.toLocaleUpperCase());
        }
    }
    const loginContainerStyle = {
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '5px 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '40px',
        margin: `${80}px`,
       
    };

    const googleSignInStyle = {
        cursor: 'pointer',
        borderRadius: '10px',
        transition: 'background-color 0.3s ease-in-out',
    };

    const googleSignInHoverStyle = {
        backgroundColor: '#f0f0f0',
    };
    return (
        <div className="container">
        <div className="row justify-content-center text-black">
            <div className="col-md-6 py-6 px-6" style={loginContainerStyle}>
                <div className="text-center mb-5">
                    <h2>SIGNUP HERE !!</h2>
                    {loading && <RevolvingDot color="#EC653D" height={100} width="100%" radius={15} ariaLabel="loading" />}
                    {error && <p className="text-danger">{error}</p>}
                </div>
                {/* <form onSubmit={handlePasswordLoginIn}>
                    <div className="form-group">
                        <label>Email</label>
                        <input required className="form-control" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input required className="form-control" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </div>
                    <Link to="/forgotpassword" className="text-muted mb-3 d-block">Forgot Password?</Link>
                    <div className="text-center">
  <button className="btn btn-primary btn-lg btn-block" type="submit" disabled={loading}>
    Log In
  </button>
  <div className="mt-4">OR</div>
</div>
                </form> */}
                <div>
                    <p className=" text-center font-bold mb-5">Choose the account type</p>
                    <div className=" flex gap-4 mb-5 items-center justify-center">
                    <button className={` ${accountType=='Student'? "bg-black" :" "} btn btn-primary btn-lg text-2xl` } onClick={() => setAccountType("Student")}>Student</button>
                    <button className={` ${accountType=='Instructor'? "bg-black" :" "} btn btn-primary btn-lg text-2xl` } onClick={() => setAccountType("Instructor")}>Instructor</button>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <button
                        className="btn btn-primary btn-lg btn-block"
                        style={{ ...googleSignInStyle, ...(loading && googleSignInHoverStyle) }}
                        onClick={handleGoogleSignIn}
                    >
                        <div >
                            <h4>Sign up with Google</h4>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Signup;
