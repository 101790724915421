import { useContext, createContext, useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signOut, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../data/firebase";

import { doc, getDoc, writeBatch, increment, setDoc } from 'firebase/firestore';
import { db } from "../data/firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const [user, setUser] = useState(null);

    const googleSignIn = async (type=null) => {
        try{
        const provider = new GoogleAuthProvider();
        const result=await signInWithPopup(auth, provider);
        const currentUser=result.user;
        if (type !== null) {
            // This is a sign-up flow
            const userDocRef = doc(db, "Users", currentUser.email);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                // User already exists in the DB
                setUser(userDocSnap.data());
                var obj = { name: currentUser.displayName, email:currentUser.email};
                localStorage.setItem('person', JSON.stringify(obj));
            } else{
                const newUser = {
                    email: currentUser.email,
                    name: currentUser.displayName || "Anonymous",
                    accountType: type
                };
                await setDoc(userDocRef, newUser);
                setUser(newUser);
                if(type==="Instructor"){
                    const activeUserDocRef = doc(db, "Active Users", currentUser.email);
                    const newActiveUser={
                        Type: "Instructor",
                        Email: currentUser.email,
                        Courses:[]
                    }
                    await setDoc(activeUserDocRef,newActiveUser);
                }
                var obj = { name: currentUser.displayName, email:currentUser.email};
                localStorage.setItem('person', JSON.stringify(obj));
            }
        }
        else {
            // This is a login flow
            const userDocRef = doc(db, "Users", currentUser.email);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                // User exists in the DB, set user state
                setUser(userDocSnap.data());
                var obj = { name: currentUser.displayName, email:currentUser.email};
                localStorage.setItem('person', JSON.stringify(obj));
            } else {
                // User does not exist, ask them to sign up
                console.log("User not found in DB, please sign up.");
            }
        }   
    }
    catch(e){
        console.error("Error during Google sign-in: ", e);
    } 
    }


    const passwordSignUp = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    const passwordSignIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    }

    const forgotPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    }

    const logout = () => {
        localStorage.clear();
        signOut(auth);
        setUser(null);
    }

    const isDocumentPresent = async (user) => {

        // console.log('I m here');

        // // user.uid
        // const docRef = doc(db, "Users", user.uid);
        // const docSnap = await getDoc(docRef);

        // if (docSnap.exists()) {
        //     // console.log("Document data:", docSnap.data());
        //     // Do nothing
        // } else {
        //     // doc.data() will be undefined in this case
        //     console.log("No such document!");
        //     // Add doc and update the count
        //     const batch = writeBatch(db);

        //     const userCountRef = doc(db, "generalInfo", "Users");
        //     batch.update(userCountRef, {
        //         count: increment(1)
        //     })

        //     const userRef = doc(db, "Users", user.uid);
        //     batch.set(userRef, {
        //         authId: user.uid,
        //         type: 'patient'
        //     })

        //     await batch.commit();
        // }
        

    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser !== null) {
                if (currentUser.displayName !== null) {
                    const userDocRef = doc(db, "Users", currentUser.email);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        setUser(userDocSnap.data());
                        var obj = { name: currentUser.displayName, email:currentUser.email};
                        localStorage.setItem('person', JSON.stringify(obj));
                    }   
                }
            }
           
        })

        return () => unsubscribe();
    }, [])

    return (
        <AuthContext.Provider value={{ googleSignIn, user, logout, passwordSignIn, passwordSignUp, forgotPassword }}>
            {children}
        </AuthContext.Provider>
    );
}

export const UserAuth = () => {
    return useContext(AuthContext);
}