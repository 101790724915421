import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { SidebarProvider } from "./context/sidebar_context";
import { CoursesProvider } from "./context/courses_context";
import { CartProvider } from "./context/cart_context";
import { AuthContextProvider } from "./context/AuthContext";
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from "react-redux";
import store from "./reducers/store";
import { QueryClient, QueryClientProvider } from "react-query";
const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();
root.render(
  <ChakraProvider>
    <Provider store={store}>
    <AuthContextProvider>
    <SidebarProvider>
      <CoursesProvider>
        <CartProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </CartProvider>
      </CoursesProvider>
    </SidebarProvider>
    </AuthContextProvider>
  </Provider>
  </ChakraProvider>
);
