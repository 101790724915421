import React from "react";
import styled from "styled-components";
import { other_images } from "../utils/images";  // Assuming you have the background image path

const Hero = () => {
  return (
    <HeroWrapper>
      <div className="container h-100 flex">
        <div className="hero-content">
          <h1>New to Android Connectz? <br /> Lucky you!</h1>
          <p>Courses starting at ₹499. Get your new-student offer before it expires.</p>
          <button className="cta-btn">Explore Now</button>
        </div>
      </div>
    </HeroWrapper>
  );
};

const HeroWrapper = styled.div`
  background: url(${other_images.hero_img}) center/cover no-repeat;
  height: 450px;
  display: flex;
  align-items: center;  // Vertically center content
  justify-content: center;  // Horizontally center content
  position: relative;

  .hero-content {
    text-align: center;  // Center the content
    max-width: 500px;  // Restrict max width for better readability
    padding: 20px;
    color: #333;  // Dark text color for contrast

    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      font-weight: bold;
      line-height: 1.3;
      color: #333;  // Dark color for heading
      text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);  // Light shadow for better readability on white background
    }

    p {
      font-size: 18px;
      margin-bottom: 30px;
      font-weight: 500;
      opacity: 0.8;
      color: #666;  // Slightly lighter color for the paragraph
    }

    .cta-btn {
      padding: 12px 30px;
      font-size: 18px;
      font-weight: 600;
      background-color: #007bff;  // Primary button color
      color: #fff;
      border: none;
      border-radius: 30px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      text-transform: uppercase;

      &:hover {
        background-color: #0056b3;  // Darker blue on hover
        transform: scale(1.05);  // Slight scaling effect for interactivity
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 400px;  // Adjust height for tablet screens

    .hero-content {
      max-width: 90%;  // Allow for more width on smaller screens
      padding: 20px;

      h1 {
        font-size: 28px;
      }

      p {
        font-size: 16px;
      }

      .cta-btn {
        font-size: 16px;
        padding: 10px 20px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    background-position: top;  // Adjust background positioning for better fit
    height: 300px;  // Reduced height for mobile screens
    padding: 50px 0;  // Adjust padding for small screens

    .hero-content {
      max-width: 80%;  // Ensure the content doesn't stretch too much
      padding: 15px;

      h1 {
        font-size: 22px;
        line-height: 1.3;
      }

      p {
        font-size: 14px;
      }

      .cta-btn {
        font-size: 14px;
        padding: 8px 16px;
      }
    }
  }
`;

export default Hero;
