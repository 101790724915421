import { collection, query, getDocs, deleteDoc, limit, writeBatch } from "firebase/firestore";

export async function deleteCollection(db, collectionPath, batchSize = 10) {
    const collectionRef = collection(db, collectionPath);
    const collectionQuery = query(collectionRef, limit(batchSize));

    return new Promise((resolve, reject) => {
        deleteQueryBatch(db, collectionQuery, resolve).catch(reject);
    });
}

async function deleteQueryBatch(db, collectionQuery, resolve) {
    const snapshot = await getDocs(collectionQuery);

    if (snapshot.empty) {
        resolve(); // All documents deleted, resolve the promise
        return;
    }

    const batch = writeBatch(db);
    snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
    });

    await batch.commit();

    // Recursively delete the next batch
    return deleteQueryBatch(db, collectionQuery, resolve);
}
