import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStep, updateQuizzes } from '../../../reducers/AddCourseSlice';

const QuizBuilder = () => {
  const dispatch = useDispatch();
  const { course } = useSelector((state) => state.course);
  
  // Initialize questions from Redux store or empty array
  const [questions, setQuestions] = useState(course?.quiz || []);
  const [errors, setErrors] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({
    Question: '',
    Opa: '',
    Opb: '',
    Opc: '',
    Opd: '',
    Ans: ''
  });

  // Update Redux store whenever questions change
  useEffect(() => {
    dispatch(updateQuizzes(questions));
  }, [questions, dispatch]);

  const validateQuestion = (question) => {
    const newErrors = {};

    // Check if all fields are filled
    if (!question.Question.trim()) {
      newErrors.Question = 'Question is required';
    }
    if (!question.Opa.trim()) {
      newErrors.Opa = 'Option A is required';
    }
    if (!question.Opb.trim()) {
      newErrors.Opb = 'Option B is required';
    }
    if (!question.Opc.trim()) {
      newErrors.Opc = 'Option C is required';
    }
    if (!question.Opd.trim()) {
      newErrors.Opd = 'Option D is required';
    }
    if (!question.Ans.trim()) {
      newErrors.Ans = 'Answer is required';
    }

    // Check if answer matches one of the options
    if (question.Ans && ![question.Opa, question.Opb, question.Opc, question.Opd].includes(question.Ans)) {
      newErrors.Ans = 'Answer must match one of the options';
    }

    return newErrors;
  };

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setCurrentQuestion(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error for this field if it exists
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleAddQuestion = () => {
    const validationErrors = validateQuestion(currentQuestion);
    if (Object.keys(validationErrors).length === 0) {
      setQuestions(prev => [...prev, currentQuestion]);
      setCurrentQuestion({
        Question: '',
        Opa: '',
        Opb: '',
        Opc: '',
        Opd: '',
        Ans: ''
      });
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  const handleDeleteQuestion = (index) => {
    setQuestions(prev => prev.filter((_, i) => i !== index));
  };

  const handleEditQuestion = (index) => {
    setCurrentQuestion(questions[index]);
    handleDeleteQuestion(index);
  };

  const validateAllQuestions = () => {
    if (questions.length === 0) {
      setErrors({ general: 'At least one question is required' });
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateAllQuestions()) {
      dispatch(setStep(4));
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6">Create Quiz</h2>

      {/* Question Form */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Question</label>
          <textarea
            name="Question"
            value={currentQuestion.Question}
            onChange={handleQuestionChange}
            className={`w-full p-3 border rounded-lg ${errors.Question ? 'border-red-500' : 'border-gray-300'}`}
            rows="3"
          />
          {errors.Question && <p className="text-red-500 text-sm mt-1">{errors.Question}</p>}
        </div>

        {/* Options */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block font-medium mb-2">Option A</label>
            <input
              type="text"
              name="Opa"
              value={currentQuestion.Opa}
              onChange={handleQuestionChange}
              className={`w-full p-3 border rounded-lg ${errors.Opa ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.Opa && <p className="text-red-500 text-sm mt-1">{errors.Opa}</p>}
          </div>

          <div>
            <label className="block font-medium mb-2">Option B</label>
            <input
              type="text"
              name="Opb"
              value={currentQuestion.Opb}
              onChange={handleQuestionChange}
              className={`w-full p-3 border rounded-lg ${errors.Opb ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.Opb && <p className="text-red-500 text-sm mt-1">{errors.Opb}</p>}
          </div>

          <div>
            <label className="block font-medium mb-2">Option C</label>
            <input
              type="text"
              name="Opc"
              value={currentQuestion.Opc}
              onChange={handleQuestionChange}
              className={`w-full p-3 border rounded-lg ${errors.Opc ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.Opc && <p className="text-red-500 text-sm mt-1">{errors.Opc}</p>}
          </div>

          <div>
            <label className="block font-medium mb-2">Option D</label>
            <input
              type="text"
              name="Opd"
              value={currentQuestion.Opd}
              onChange={handleQuestionChange}
              className={`w-full p-3 border rounded-lg ${errors.Opd ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.Opd && <p className="text-red-500 text-sm mt-1">{errors.Opd}</p>}
          </div>
        </div>

        {/* Answer */}
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Correct Answer</label>
          <input
            type="text"
            name="Ans"
            value={currentQuestion.Ans}
            onChange={handleQuestionChange}
            className={`w-full p-3 border rounded-lg ${errors.Ans ? 'border-red-500' : 'border-gray-300'}`}
          />
          {errors.Ans && <p className="text-red-500 text-sm mt-1">{errors.Ans}</p>}
        </div>

        <button
          onClick={handleAddQuestion}
          className="w-full px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
        >
          Add Question
        </button>
      </div>

      {/* Questions List */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Added Questions ({questions.length})</h3>
        {errors.general && <p className="text-red-500 text-sm mb-4">{errors.general}</p>}
        
        {questions.map((question, index) => (
          <div key={index} className="bg-gray-50 p-4 rounded-lg mb-4">
            <div className="flex justify-between items-start mb-2">
              <h4 className="font-medium">Question {index + 1}</h4>
              <div>
                <button
                  onClick={() => handleEditQuestion(index)}
                  className="text-blue-600 hover:text-blue-800 mr-4"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteQuestion(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  Delete
                </button>
              </div>
            </div>
            <p className="mb-2">{question.Question}</p>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <p>A: {question.Opa}</p>
              <p>B: {question.Opb}</p>
              <p>C: {question.Opc}</p>
              <p>D: {question.Opd}</p>
            </div>
            <p className="mt-2 text-green-600">Correct Answer: {question.Ans}</p>
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between pt-6">
        <button
          onClick={() => dispatch(setStep(2))}
          className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
        >
          Back
        </button>
        <button
          onClick={handleNext}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default QuizBuilder;