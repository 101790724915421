import React, { useState } from 'react';
import styled from "styled-components";
import Course from "./Course";
import { ALL, PYTHON, WEB_DEVELOPMENT, DATA_SCIENCE, AWS, DESIGN, MARKETING } from "../utils/constants";
import courses from '../utils/data';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(ALL);

  const tabHandler = (category) => {
    setActiveTab(category);
  };

  return (
    <TabsWrapper>
      <div className="tabs">
        <ul className="tabs-head flex flex-wrap">
          <li className="tabs-head-item">
            <button
              type="button"
              className={`tab-btn ${activeTab === ALL ? "active" : ""}`}
              onClick={() => tabHandler(ALL)}
            >
              All
            </button>
          </li>
          <li className="tabs-head-item">
            <button
              type="button"
              className={`tab-btn ${activeTab === PYTHON ? "active" : ""}`}
              onClick={() => tabHandler(PYTHON)}
            >
              Research
            </button>
          </li>
          <li className="tabs-head-item">
            <button
              type="button"
              className={`tab-btn ${activeTab === WEB_DEVELOPMENT ? "active" : ""}`}
              onClick={() => tabHandler(WEB_DEVELOPMENT)}
            >
              Data Science
            </button>
          </li>
          <li className="tabs-head-item">
            <button
              type="button"
              className={`tab-btn ${activeTab === DATA_SCIENCE ? "active" : ""}`}
              onClick={() => tabHandler(DATA_SCIENCE)}
            >
              Web Development
            </button>
          </li>
          <li className="tabs-head-item">
            <button
              type="button"
              className={`tab-btn ${activeTab === AWS ? "active" : ""}`}
              onClick={() => tabHandler(AWS)}
            >
              Advanced Technology
            </button>
          </li>
          <li className="tabs-head-item">
            <button
              type="button"
              className={`tab-btn ${activeTab === DESIGN ? "active" : ""}`}
              onClick={() => tabHandler(DESIGN)}
            >
              Summer School
            </button>
          </li>
          <li className="tabs-head-item">
            <button
              type="button"
              className={`tab-btn ${activeTab === MARKETING ? "active" : ""}`}
              onClick={() => tabHandler(MARKETING)}
            >
              Internship
            </button>
          </li>
        </ul>

        <div className="tabs-body">
          {courses
            .filter((course) => activeTab === ALL || course.category.includes(activeTab))
            .map((course) => (
              <Course key={course.id} {...course} />
            ))}
        </div>
      </div>
    </TabsWrapper>
  );
};

const TabsWrapper = styled.div`
  .tabs {
    margin-top: 24px;
    .tabs-head {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 30px;
      padding: 0;
    }

    .tabs-head-item {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .tab-btn {
      border: 1px solid #ddd;
      background-color: #f9f9f9;
      padding: 12px 20px;
      font-size: 16px;
      font-weight: 500;
      color: #333;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #007bff;
        color: white;
        border-color: #007bff;
      }

      &.active {
        background-color: #007bff;
        color: white;
        border-color: #007bff;
        box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
      }
    }

    .tabs-body {
      margin-top: 30px;
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(1, 1fr);

      @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (min-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
`;

export default Tabs;
