import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStep,
  resetCourseState,
} from "../../../reducers/AddCourseSlice";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../data/firebase";
import { UserAuth } from "../../../context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
const PublishCourse = () => {
  let { courseId } = useParams();
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const { course } = useSelector((state) => state.course);
  const { editCourse } = useSelector((state) => state.course);
  const { user } = UserAuth();
  const [isPublishing, setIsPublishing] = useState(false);
  const [error, setError] = useState(null);

  const getNextCourseNumber = async () => {
    try {
      let currCount = await getDoc(doc(db, "Course Count", "Count"));
      let currCountVal = currCount.data().Count;
      await updateDoc(doc(db, "Course Count", "Count"), {
        Count: increment(1),
      });

      let newVal = currCountVal + 1;
      return newVal;
    } catch (error) {
      console.error("Error getting next course number:", error);
      throw error;
    }
  };

  const publishCourse = async () => {
    setIsPublishing(true);
    setError(null);

    try {
      let courseName;
      if (editCourse) {
        courseName = courseId.replace(/Course(\d+)/, "Course $1");
      } else {
        let courseNumber = await getNextCourseNumber();
        courseName = `Course ${courseNumber}`;
      }

      // Create course collection reference
      const courseCollectionRef = collection(db, courseName);

      // 1. Publish course details
      await setDoc(doc(courseCollectionRef, "Detail"), {
        CourseId:courseName,
        Name: course.name,
        Description: course.description,
        Price: course.price,
        Photo: course.thumbnailLink,
        After_Completion: course.afterCompletion,
        Duration: course.duration,
        Mission: course.mission,
        Note_To_Students: course.noteToStudents,
        Outcome: course.outcome,
        Category: course.categories,
        About_Author: user.name,
        Published: false,
        Rating: "5",
      });

      // 2. Publish course sections
      for (let i = 0; i < course.sections.length; i++) {
        await setDoc(doc(courseCollectionRef, (i + 1).toString()), {
          Chapter: i + 1,
          Lectures: course.sections[i].lectures,
        });
      }

      // 3. Publish quiz questions
      if (course.quiz && course.quiz.length > 0) {
        await setDoc(doc(db, "Quiz Questions", courseName), {
          Course: courseName,
          Questions: course.quiz,
        });
      }
      // 4. Publish free videos
      if (course.freeVideos && course.freeVideos.length > 0) {
        await setDoc(doc(db, "Free Videos", courseName), {
          Course: courseName,
          Video: course.freeVideos,
        });
      }

      if (!editCourse) {
        const docRef = doc(db, "Active Users", user.email); // Replace 'users' and 'userId' with your actual collection and document ID

        // Update document by adding new course
        await updateDoc(docRef, {
          Courses: arrayUnion({ Course: courseName }),
        });
      }
      // Reset course state and show success message
      dispatch(resetCourseState());
      alert("Course published successfully!");
      if(editCourse){
        navigate('/instructorCourses')
      }
    } catch (error) {
      console.error("Error publishing course:", error);
      setError("Failed to publish course. Please try again.");
    } finally {
      setIsPublishing(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6">{`${editCourse? "Edit Course": "Publish Course"}`}</h2>

      {/* Course Preview Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h3 className="text-xl font-bold mb-4">Course Overview</h3>

        <div className="space-y-4">
          <div>
            <h4 className="font-medium">Course Name</h4>
            <p className="text-gray-700">{course.name}</p>
          </div>

          <div>
            <h4 className="font-medium">Description</h4>
            <p className="text-gray-700">{course.description}</p>
          </div>

          <div>
            <h4 className="font-medium">Price</h4>
            <p className="text-gray-700">₹{course.price}</p>
          </div>

          <div>
            <h4 className="font-medium">Categories</h4>
            <p className="text-gray-700">{course.categories}</p>
          </div>

          <div>
            <h4 className="font-medium">Duration</h4>
            <p className="text-gray-700">{course.duration}</p>
          </div>

          <div>
            <h4 className="font-medium">Sections</h4>
            <p className="text-gray-700">{course.sections.length} sections</p>
          </div>

          <div>
            <h4 className="font-medium">Quiz Questions</h4>
            <p className="text-gray-700">
              {course.quiz?.length || 0} questions
            </p>
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex justify-between">
        <button
          onClick={() => dispatch(setStep(3))}
          className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
          disabled={isPublishing}
        >
          Back
        </button>

        <div className="space-x-4">
          <button
            onClick={() => dispatch(resetCourseState())}
            className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            disabled={isPublishing}
          >
            Cancel
          </button>

          <button
            onClick={publishCourse}
            disabled={isPublishing}
            className={`px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 ${
              isPublishing ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isPublishing
              ? `${editCourse ? "Editing...." : "Publishing....."}`
              : `${editCourse ? "Edit Course" : "Publish Course"}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublishCourse;
