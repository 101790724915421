import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";

export default function ChipInput({
  label,
  name,
  placeholder,
  setValue,
  initialValue = [],
}) {
  const [chips, setChips] = useState(initialValue); // Filter out empty initial values

  useEffect(() => {
    setValue(chips);
  }, [chips, setValue]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const chipValue = event.target.value.trim();
      if (chipValue && !chips.includes(chipValue)) {
        setChips([...chips, chipValue]);
        event.target.value = "";
      }
    }
  };

  const handleDeleteChip = (chipIndex) => {
    const newChips = chips.filter((_, index) => index !== chipIndex);
    setChips(newChips);
  };

  return (
    <div className="space-y-2">
      <label className="block text-4xl font-medium text-gray-700" htmlFor={name}>
        {label} <sup className="text-red-500">*</sup>
      </label>
      <div className="flex w-full flex-wrap gap-y-2 text-3xl">
        { chips.map((chip, index) => (
          <div
            key={index}
            className="m-1 flex items-center rounded-full bg-blue-500 px-2 py-1 text-3xl text-white"
          >
            {chip}
            <button
              type="button"
              className="ml-2 focus:outline-none"
              onClick={() => handleDeleteChip(index)}
            >
              <MdClose className="text-3xl" />
            </button>
          </div>
        ))}
        <input
          id={name}
          name={name}
          type="text"
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-10 min-h-10 bg-slate-400"
        />
      </div>
    </div>
  );
}
