import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, SingleCourse, Cart, Courses } from "./pages";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Login from "./Auth/Login";
import ActiveCoursesPage from "./pages/ActiveCoursePage";
import CourseChapters from "./components/CourseChapter";
import Profile from "./components/Profile";
import AdroidconnectzPage from "./About/AdroidconnectzPage";
import VisionPage from "./About/VisionPage";
import FeesAndPayments from "./About/FeesAndPayment";
import MissionPage from "./About/MissionPage";
import PrivacyPolicy from "./About/PrivacyPolicy";
import SupportPage from "./About/Support";
import TermsAndConditions from "./About/TermsAndConditions";
import SuccessPage from "./Payment/Success";
import CancelPage from "./Payment/Cancel";
import Signup from "./Auth/Signup";
import InstructorCourses from "./pages/Instructor/InstructorCourses";
import { UserAuth } from "./context/AuthContext";
import ErrorRoute from "./pages/ErrorRoute";
import InstructorDashboard from "./components/InstructorDashboard/InstructorDashboard";
import RenderSteps from "./components/AddCourse/RenderSteps";
import EditCourse from "./components/EditCourse/EditCourse";

function App() {

  
  const { user} = UserAuth();
  return (
    <BrowserRouter>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="*" element={<ErrorRoute/>}/>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/courses/:id" element={<SingleCourse />} />
        <Route path="/ActiveCourses/:email" element={<ActiveCoursesPage />} />
        <Route path="/ActiveCourseChapter/:id" element={<CourseChapters />} />
        <Route path="/category/:category" element={<Courses />} />
        <Route path="/cart" element={<Cart/>} />
        <Route path="/cancel" element={<CancelPage/>} />

        
        <Route path="/about" element={<AdroidconnectzPage />} />
        <Route path="/vision" element={<VisionPage />} />
        <Route path="/mission" element={<MissionPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/refundpolicy" element={<FeesAndPayments />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/success" element={<SuccessPage />} />

        {/* instructor routes */}
        {user?.accountType === "Instructor" && (
                <>
                  <Route
                    path="/instructorCourses"
                    element={<InstructorCourses />}
                  />
                  <Route path="/dashboard/instructor" element={<InstructorDashboard/>}/>
                  <Route path="/dashboard/addCourse" element={<RenderSteps/>}/>
                  <Route path="/dashboard/edit-course/:courseId" element={<EditCourse />} />
                </>
      )}             
      </Routes>
    </BrowserRouter>
  );
}

export default App;
