import React from "react";
import Hero from "../components/Hero";
import CoursesList from "../components/CourseList";
import CategoriesList from "../components/CategoriesList";
import Banner from "../components/Banner";
import { UserAuth } from "../context/AuthContext";
import VideoSection from "../components/VideoSection";
const HomePage = () => {
  const { user } = UserAuth();
  return (
    <div className="holder">
      <Hero />
      {/* <Banner /> */}
      {user && user.accountType=="Instructor"?<VideoSection />:""};
      <CoursesList />
      <CategoriesList />
    </div>
  );
};

export default HomePage;
