import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../data/firebase";


// Function to calculate enrolled students and total revenue for a specific instructor by email
export const getInstructorData=async (user)=> {
    // Array to hold the results
    const results = [];

    // Step 1: Get the instructor's document by email
    const instructorDocRef = doc(db, "Active Users", user.email);
    const instructorDoc = await getDoc(instructorDocRef);

    if (instructorDoc.exists()) {
        const instructorData = instructorDoc.data();
        const courses = instructorData.Courses || [];
        console.log("thse are current ins courses", courses)
        // Step 2: Iterate over each course created by the instructor
        for (let courseObj of courses) {
            const courseName = courseObj.Course;

            // Step 3: Fetch all students enrolled in this course (exclude instructors by filtering out those with a "Type" field)
         
            
           const totalStudentsEnrolled=await countEnrolledStudents(courseName);

            // Step 4: Fetch the course price from the course's collection
            const courseDetailDocRef = doc(db, courseName, "Detail");
            const courseDetailDoc = await getDoc(courseDetailDocRef);
            const coursePrice = courseDetailDoc.exists() ? courseDetailDoc.data().Price : 0;
            const currCourseName=courseDetailDoc.data().Name;
            const courseDescription=courseDetailDoc.data().Description;
            // Step 5: Calculate total revenue
            const totalRevenue = totalStudentsEnrolled * coursePrice;

            // Step 6: Add the result to the array
            results.push({
                courseName: currCourseName,
                courseDescription:courseDescription,
                totalStudentsEnrolled,
                totalAmountGenerated: totalRevenue
            });
        }

        // Return the results array after processing all courses
        return results;
    } else {
        console.log("Instructor not found");
        return [];
    }
}

async function countEnrolledStudents(courseName) {
    let count = 0;

    // Fetch all users to check for enrolled students
    const usersSnapshot = await getDocs(collection(db, "Active Users"));
    
    usersSnapshot.forEach(doc => {
        const data = doc.data();
        // Check if the user is a student (no Type field)
        if (!data.Type) {
            const courses = data.Courses || [];
            // Check if the course is in the user's courses
            courses.forEach(course => {
                if(course.Course === courseName){
                    count++;
                }
            })
            
        }
    });

    return count;
}