import React, { useEffect, useState, Suspense, lazy, useMemo } from "react";
import { fetchInstructorCourses } from "../../operations/courseDetailsAPI";
import { getInstructorData } from "../../operations/ProfileAPI";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import InstructorChart from './InstructorChart';
import { useQuery } from 'react-query';

export default function InstructorDashboard() {
  const { user } = UserAuth();

  // Using React Query to cache the data
  const { data: instructorData, isLoading: instructorLoading } = useQuery(
    ['instructorData', user],
    () => getInstructorData(user),
    { staleTime: 60000 }
  );
  
  const { data: courses, isLoading: coursesLoading } = useQuery(
    ['coursesData', user],
    () => fetchInstructorCourses(user),
    { staleTime: 60000 }
  );

  const totalAmount = useMemo(() => {
    return instructorData?.reduce((acc, curr) => acc + curr.totalAmountGenerated, 0) || 0;
  }, [instructorData]);

  const totalStudents = useMemo(() => {
    return instructorData?.reduce((acc, curr) => acc + curr.totalStudentsEnrolled, 0) || 0;
  }, [instructorData]);

  if (instructorLoading || coursesLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="custom-loader"></div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="space-y-2 mt-1 p-10">
        <h1 className="text-2xl font-bold text-gray-800">Hi {user?.name} 👋</h1>
        <p className="font-medium text-gray-600">Let's start something new</p>
      </div>
      {courses && courses.length > 0 ? (
        <div>
          <div className="my-4 flex min-h-[450px] space-x-4 p-10">
            {/* Render chart / graph */}
            {totalAmount > 0 || totalStudents > 0 ? (
              <Suspense fallback={<div>Loading Chart...</div>}>
                <InstructorChart courses={instructorData} />
              </Suspense>
            ) : (
              <div className="flex-1 rounded-md bg-gray-200 p-6">
                <p className="text-lg font-bold text-gray-700">Visualize</p>
                <p className="mt-4 text-xl font-medium text-gray-500">
                  Not Enough Data To Visualize
                </p>
              </div>
            )}

            {/* Total Statistics */}
            <div className="flex min-w-[250px] flex-col rounded-md bg-blue-50 h-full pb-10">
              <p className="font-bold text-gray-700 text-4xl mt-5 underline">
                Statistics
              </p>
              <div className="mt-20 space-y-10">
                <div>
                  <p className="text-3xl text-gray-500">Total Courses</p>
                  <p className="text-3xl font-semibold text-gray-700">
                    {courses.length}
                  </p>
                </div>
                <div>
                  <p className="text-3xl text-gray-500">Total Students</p>
                  <p className="text-3xl font-semibold text-gray-700">
                    {totalStudents}
                  </p>
                </div>
                <div>
                  <p className="text-3xl text-gray-500">Total Income</p>
                  <p className="text-3xl font-semibold text-gray-700">
                    Rs. {totalAmount}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-md bg-gray-200 p-6">
            {/* Render 3 courses */}
            <div className="flex items-center justify-between">
              <p className="text-4xl mt-10 font-bold text-gray-800">
                Your Courses
              </p>
              <Link to="/instructorCourses">
                <p className="text-3xl font-semibold text-yellow-600">
                  View All
                </p>
              </Link>
            </div>
            <div className="my-4 flex items-start space-x-6 gap-5">
              {courses.slice(0, 3).map((course) => (
                <div key={course.CourseId} className="w-1/3">
                  <img
                    src={course.Photo}
                    alt={course.Name}
                    className="h-[201px] w-full rounded-md object-cover"
                  />
                  <div className="mt-3 w-full">
                    <p className="text-2xl font-medium text-gray-700">
                      {course.Name}
                    </p>
                    <div className="mt-1 flex items-center space-x-2">
                      <p className="text-xl font-medium text-gray-500">
                        Rs. {course.Price}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-20 rounded-md bg-gray-200 p-6 py-20">
          <p className="text-center text-2xl font-bold text-gray-800">
            You have not created any courses yet
          </p>
          <Link to="/dashboard/add-course">
            <p className="mt-1 text-center text-lg font-semibold text-yellow-600">
              Create a course
            </p>
          </Link>
        </div>
      )}
    </div>
  );
}
