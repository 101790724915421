import React from 'react';
import styled from "styled-components";
import Tabs from "./Tabs";
import { useCoursesContext } from '../context/courses_context';

const CourseList = () => {
  const { courses } = useCoursesContext();

  return (
    <CoursesListWrapper>
      <div className='container'>
        <div className='courses-list-top'>
          <h2>A Broad Selection of Courses</h2>
          <p>Choose from 100 online video courses, with new additions published every month.</p>
        </div>

        <Tabs courses={courses} />
      </div>
    </CoursesListWrapper>
  );
};

const CoursesListWrapper = styled.div`
  padding: 60px 0;
  background-color: #f7f9fc;
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .courses-list-top {
    text-align: center;
    margin-bottom: 40px;
  }

  .courses-list-top h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
  }

  .courses-list-top p {
    font-size: 5 rem;
    color: #555;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0;

    .courses-list-top h2 {
      font-size: 2rem;
    }

    .courses-list-top p {
      font-size: 1rem;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 30px 0;

    .courses-list-top h2 {
      font-size: 1.8rem;
    }

    .courses-list-top p {
      font-size: 0.9rem;
    }
  }
`;

export default CourseList;
