import React from 'react'
import { useSelector } from 'react-redux';
import CourseInformation from './CourseInformation/CourseInformation';
import CourseBuilder from './CourseBuilder/CourseBuilder'
import PublishCourse from './PublishCourse/PublishCourse';
import QuizBuilder from './QuizBuilder/QuizBuilder';

function RenderSteps() {
    const {step}=useSelector((state)=> state.course)

  return (
    <div>
        {step==1 && <CourseInformation/>}
        {step==2 && <CourseBuilder/>}
        {step==3 && <QuizBuilder/>}
        {step==4 && <PublishCourse/>}
    </div>
  )
}

export default RenderSteps